import styled from 'styled-components';

const HeaderBlock = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
`

export { HeaderBlock }
