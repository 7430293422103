import styled from "styled-components"

const SocialRow = styled.div`
  align-items: center;
  padding: 20px 0;
  display: flex;
  gap: 10px;
`

const SocialLink = styled.a`
  align-items: center;
  background-color: ${({ variant }) => variant === "rounded" && "var(--color-negro)"};
  border-radius: 50%;
  color: ${({ variant }) => variant === "rounded" ? "var(--color-blanco)" : "var(--color-negro)"};
  display: grid;
  font-size: ${({ fontSize }) => fontSize};
  height: 30px;
  justify-content: center;
  transition: color 0.3s;
  width: 30px;

  &:hover {
    color: var(--color-principal);
  }
`

export { SocialRow, SocialLink }