import React from "react"
import "./styles.css"
import { createGlobalStyle } from "styled-components"
import Header from "./Header"
import Footer from "./Footer"

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}

const GlobalStyle = createGlobalStyle`
  :root {
    --color-principal: #DBB045;
    --color-promesas: #94d31d;
    --color-blanco: #fff;
    --color-negro: #000;
    --color-gris: #808080;
    --font-principal: "Averta";
  }

  html {
    font-size: 16px;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  ::selection {
  }

  body {
    font-family: var(--font-principal);
    font-size: 1rem;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
  }

  h1, h2, h3, h4, h5, h6 {  
    margin: 0;
  }

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
  }
`

export default Layout
