import React from "react"
import { Section } from "../Utilities/section"
import { LogoWithText } from "./logoWithText"
import { FooterRow } from "./footerRow"
import { Column } from "./column"
import { Link } from "gatsby"
import styled from "styled-components"
import { BsFacebook } from "react-icons/bs"
import { FaInstagram, FaLinkedinIn } from "react-icons/fa"
import { SocialLink, SocialRow } from "../Utilities/social"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Footer = () => {
  return (
    <Section backgroundColor="#EAE8E4">
      <FooterRow>
        <Column $size={2}>
          <LogoWithText />
        </Column>
        <Column title="Services">
          <List>
            <li>
              <AnchorLink to="/#services">Brand</AnchorLink>
            </li>
            <li>
              <AnchorLink to="/#services">Copy</AnchorLink>
            </li>
            <li>
              <AnchorLink to="/#services">Digital</AnchorLink>
            </li>
            <li>
              <AnchorLink to="/#services">Design</AnchorLink>
            </li>
            <li>
              <Link to="/">Media</Link>
            </li>
          </List>
        </Column>
        <Column title="Follow us">
          <SocialRow>
            <SocialLink href="https://www.instagram.com/lorogranada/" target="_blank" variant="rounded" fontSize="20px">
              <FaInstagram />
            </SocialLink>

            <SocialLink href="https://www.facebook.com/Lorogranada/" target="_blank" fontSize="30px">
              <BsFacebook />
            </SocialLink>

            <SocialLink href="https://www.linkedin.com/company/lorogranada/" target="_blank" variant="rounded" fontSize="15px">
              <FaLinkedinIn />
            </SocialLink>
          </SocialRow>
        </Column>
      </FooterRow>
    </Section>
  )
}

const List = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 5px;
  
  a {
    color: var(--color-negro);
    transition: color 0.3s;

    &:hover {
      color: var(--color-principal);
    }
  }
`

export default Footer
