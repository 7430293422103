import React from "react"
import styled from "styled-components"
import { device } from "../devices"
import { StaticImage } from "gatsby-plugin-image"

const LogoWithText = () => {
  return (
    <>
      <Logo>
        <StaticImage src="../../images/TEST_logo-footer.png" alt="Header" />
      </Logo>
      <Description>We create meaningful brand experiences through purposeful innovation, creative experimentation and multiformat storytelling, using design and strategical communication.</Description>
    </>
  )
}

const Logo = styled.div`
  grid-area: 1/2;
  margin-bottom: 30px;

  .gatsby-image-wrapper {
    max-width: 170px;
  }

  ${device.tablet} {
    margin-bottom: 40px;
  }
`

const Description = styled.p`
  color: var(--color-negro);
  font-size: 16px;
  max-width: 385px;

  ${device.desktop} {
    font-size: 18px;
    max-width: 420px;
  }
`

export { LogoWithText }
