import styled from "styled-components"
import { device } from "../devices"

const Container = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%;
  z-index: 1;
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};

  ${device.tablet} {
    width: 95vw;
  }
`
export { Container }
