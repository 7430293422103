import styled from "styled-components"
import React from "react"
import { device } from "../devices"

const Column = ({ $size, title, children }) => {
  return (
    <ColumnStyled $size={$size}>
      {title && <Title>{title}</Title>}
      {children}
    </ColumnStyled>
  )
}

const ColumnStyled = styled.div`
  grid-column: ${({ $size }) => $size ? `1 / span ${$size}` : "auto"};

  ${device.tablet} {
    grid-column: auto;
  }
`

const Title = styled.h3`
  color: var(--color-negro);
  font-size: 16px;

  strong {
    font-weight: bold;
  }

  ${device.tablet} {
    font-size: 18px;
  }

  ${device.desktop} {
    font-size: 20px;
  }
`

export { Column }
