import styled from 'styled-components';
import { device } from '../devices';
import { Container } from '../Utilities/container';

const Navbar = styled(Container)`
  align-items: center;
  display: flex;
  justify-content: space-between;

  &.fixed {
    position: fixed;
  }

  .gatsby-image-wrapper {
    max-width: 150px;
    z-index: 100;
  }

  ${device.tablet} {
     &.fixed {
      position: relative;
    }
  }
`

export { Navbar };