import styled from "styled-components"
import { Container } from "./container"
import { device } from "../devices"

const Section = styled.section`
  background-color: ${({ backgroundColor }) =>
    backgroundColor || "var(--color-blanco)"};
  padding-top: 50px;
  padding-bottom: 50px;

  ${device.tablet} {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  ${device.laptop} {
    padding-top: 150px;
    padding-bottom: 150px;
  }
`

const HardSection = styled.section`
  background-color: ${({ backgroundColor }) =>
    backgroundColor || "var(--color-blanco)"};
  display: grid;
  grid-template-columns: 1fr;

  .image-container {
    height: 150px;

    .gatsby-image-wrapper {
      height: 100%;
    }
  }

  ${Container} {
    padding-bottom: 50px;
    padding-top: 50px;

    ${device.tablet} {
      padding-top: 70px;
      padding-bottom: 70px;
    }

    ${device.laptop} {
      width: auto;
      padding-left: 100px;
      padding-top: 120px;
      padding-bottom: 120px;
    }

    ${device.desktop} {
      padding-left: 150px;
      padding-top: 180px;
      padding-bottom: 200px;
    }
  }

  ${device.laptop} {
    grid-template-columns: 2fr 4fr 0.2fr;

    .image-container {
      height: 100%;
      width: 100%;

      .gatsby-image-wrapper {
        height: 100%;
        width: 100%;

        > div {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  ${device.desktop} {
    grid-template-columns: 2fr 4fr 1fr;
  }
`

export { Section, HardSection }
