import React, { useState } from "react"
import { HeaderBlock } from "./headerBlock"
import { Navbar } from "./navbar"
import { StaticImage } from "gatsby-plugin-image"
import { HamburguerButton } from "./hamburguerButton"
import { Menu, MenuItem } from "./menu"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link } from "gatsby"

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  return (
    <HeaderBlock>
      <Navbar as="nav" className={menuOpen ? "fixed" : ""}>
        <AnchorLink to="/" ><StaticImage src="../../images/logo.png" alt="Header" /></AnchorLink>
        <HamburguerButton onClick={toggleMenu} className={menuOpen ? "close" : ""} />

        <Menu className={menuOpen ? "" : "hide"}>
          <MenuItem onClick={toggleMenu}><Link to="/">Home</Link></MenuItem>
          <MenuItem onClick={toggleMenu}><AnchorLink to="/#services" stripHash>Services</AnchorLink></MenuItem>
          {/* <MenuItem><Link to="/" onClick={toggleMenu}>Our Projects</Link> </MenuItem> */}
          <MenuItem onClick={toggleMenu}><AnchorLink to="/#contact" stripHash>Let's Talk</AnchorLink></MenuItem>
        </Menu>
      </Navbar>
    </HeaderBlock>
  )
}

export default Header
